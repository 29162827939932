<template>
    <a-drawer
            placement="right"
            :closable="false"
            :visible="visible"
            width="500"
            @close="onClose"
    >
        <div class="header" v-if="rankList && rankList.length > 0">
            <img :src="myInfo.headimgurl">
            <div class="huiwen-left" style="height: 60px;width:calc(100% - 60px)">
                <div class="huiwen-left huiwen-border-box" style="width: 70%;height: 60px;padding-left: 15px;">
                    <div class="huiwen-left huiwen-container" style="height: 30px;line-height: 30px">
                        <span class="huiwen-text-title huiwen-text-white" >{{ myInfo.name }}</span>
                    </div>
                    <div class="huiwen-left huiwen-container" style="height: 30px">
                        <span class="huiwen-text-bold huiwen-text-white">{{ myRankIndex != null  ? rankList[myRankIndex].content : '未上榜，查不到您的成绩'}}</span>
                    </div>
                </div>
                <div class="huiwen-left huiwen-text-right" style="width: 30%;height: 60px">
                    <a-statistic :value="myRankIndex != null ? myRankIndex+1 : 0" valueStyle="color: #ffffff;font-size:40px">
                        <template #suffix>th</template>
                    </a-statistic>
                </div>
            </div>
        </div>
        <div class="huiwen-container huiwen-left">
            <a-menu :selectedKeys="[rankTypeIndex]" mode="horizontal" theme="dark" class="huiwen-layout-flex-left-right" @select="onSelectRankType">
                <a-menu-item v-for="(item, index) in rankTypes" :key="index" style="flex:1;text-align: center">{{ item.name }}</a-menu-item>
            </a-menu>
        </div>
        <div class="huiwen-container huiwen-left">
            <div class="rank-item huiwen-border-bottom-gray-light" v-for="(item, index) in rankList">
                <img :src="item.headimgurl">
                <div class="huiwen-left" style="height: 40px;width:calc(100% - 40px)">
                    <div class="huiwen-left huiwen-border-box huiwen-over-one" style="width: 30%;height: 40px;line-height: 40px;padding-left: 15px">
                        <span>{{item.name}}</span>
                    </div>
                    <div class="huiwen-left huiwen-border-box" style="width: 40%;height: 40px;line-height: 40px;padding-left: 15px">
                        <span class="huiwen-text-bold">{{item.content}}</span>
                    </div>
                    <div class="huiwen-left huiwen-text-right" style="width: 30%;height: 40px">
                        <template v-if="index == 0">
                            <i class="iconfont icon-first" style="font-size: 24px;color: #FFD700"></i>
                        </template>
                        <template v-if="index == 1">
                            <i class="iconfont icon-second" style="font-size: 24px;color: rgb(210,210,210);"></i>
                        </template>
                        <template v-if="index == 2">
                            <i class="iconfont icon-third" style="font-size: 24px;color: #B5A642"></i>
                        </template>
                        <template v-if="index > 2">
                            <a-statistic :value="index+1" valueStyle="font-size:14px">
                                <template #suffix>th</template>
                            </a-statistic>
                        </template>
                    </div>
                </div>
            </div>
        </div>
    </a-drawer>
</template>

<script>
    import { doQuery, doAction } from "@/api/core"
    import { mapGetters } from 'vuex'
    export default {
        name: "study-rank-data",
        data() {
            return {
                visible: false,
                /*排名类型*/
                rankTypes: [
                    {
                        name: '学习进度',
                        query_code: 'LNR10',
                    },
                    {
                        name: '正确率',
                        query_code: 'LNR20',
                    },
                    {
                        name: '学习时长',
                        query_code: 'LNR30',
                    },
                    {
                        name: '日打卡',
                        query_code: 'LNR40',
                    },
                ],
                rankTypeIndex: 0,

                /*排名数据*/
                rankList: null,
                myRankIndex:null,
                page:1,
                limit:50
            }
        },
        props: {
            taskId: String
        },
        computed: {
            ...mapGetters([
                'staffId',
                'myInfo'
            ])
        },
        watch: {
            'taskId': function(newVal, oldVal) {
                if(newVal) {
                    this.getRankData(() => {
                        this.visible = true
                    })
                }else {
                    this.visible = false
                }
            }
        },
        mounted() {

        },
        methods: {
            onClose() {
                const that = this
                that.$emit('onClose')
            },
            getRankData(func) {
                const that = this
                doQuery({
                    query_code: that.rankTypes[that.rankTypeIndex].query_code,
                    param_str2: that.taskId,
                    page:that.page,
                    limit:that.limit
                }).then(res => {
                    if(res.errcode >= that.$consts.biz.OK && res.data) {
                        that.rankList = res.data
                        //确定我的排名
                        that.myRankIndex = null
                        for (let i=0;i<that.rankList.length;i++){
                            if (that.rankList[i].staff_id == that.staffId){
                                that.myRankIndex = i;
                                break;
                            }
                        }
                        if(func) {
                            func()
                        }
                    }
                })
            },
            onSelectRankType({ item, key, selectedKeys }) {
                const that = this
                that.rankTypeIndex = key
                that.getRankData();
            }
        }
    }
</script>

<style scoped>
    /deep/ .ant-drawer-body {
        padding: 0;
        float: left;
        width: 100%;
    }
    /deep/ .ant-menu.ant-menu-dark .ant-menu-item-selected, .ant-menu-submenu-popup.ant-menu-dark .ant-menu-item-selected {
        color: #1890ff;
        background-color: #001529;
    }

    .header {
        background-color: #001529;
        width: 100%;
        float: left;
        padding: 20px 40px 10px 40px;
        box-sizing: border-box;
    }
    .header img {
        width: 60px;
        height: 60px;
        border-radius: 60px;
        float: left;
    }

    .rank-item {
        width: 100%;
        float: left;
        padding: 10px 40px;
        box-sizing: border-box;
    }
    .rank-item img {
        width: 40px;
        height: 40px;
        border-radius: 40px;
        float: left;
    }

</style>
