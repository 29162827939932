<template>
    <a-drawer
            placement="right"
            :closable="false"
            :visible="visible"
            width="500"
            @close="onClose"
    >
        <a-row :gutter="8">
            <a-col :span="12" class="huiwen-text-center">
                <a-statistic title="累积打卡" :value="signDaysTotal" >
                    <template #suffix>
                        天
                    </template>
                </a-statistic>
            </a-col>
            <a-col :span="12" class="huiwen-text-center">
                <a-statistic title="连续打卡" :value="signDaysContinue">
                    <template #suffix>
                        天
                    </template>
                </a-statistic>
            </a-col>
        </a-row>
        <a-row>
            <a-calendar :fullscreen="false" @panelChange="onPanelChange" >
                <template slot="dateCellRender" slot-scope="value">
                    <a-icon v-if="signDays.includes($moment(value).format('YYYY-MM-DD'))" style="color: #67C23A" type="check" />
                </template>
            </a-calendar>
        </a-row>
        <a-divider />
        <a-row style="margin-top: 20px">
            <div class="huiwen-container">
                <div class="huiwen-container" v-if="daliyQuestion">
                    <a-tag v-if="daliyQuestion.type == $consts.biz.QUES_TYPES.SINGLE" color="blue" >单选题</a-tag>
                    <a-tag v-if="daliyQuestion.type == $consts.biz.QUES_TYPES.MULTI" color="orange" >多选题</a-tag>
                    <a-tag v-if="daliyQuestion.type == $consts.biz.QUES_TYPES.JUDGE" color="purple" >判断题</a-tag>
                    <a-tag v-if="daliyQuestion.type == $consts.biz.QUES_TYPES.BLANK" color="green" >填空题</a-tag>
                    <div class="question-title  huiwen-text-bold huiwen-text-small-title" v-html="daliyQuestion.question"></div>
                </div>
                <div class="huiwen-container huiwen-margin-top-10" v-if="daliyQuestion">
                    <div class="huiwen-container huiwen-left huiwen-margin-bottom-10" v-for="item in daliyQuestion.options">
                        <template v-if="item.text">
                            <span class="huiwen-text-bold">{{ item.number }}：&nbsp;&nbsp;</span>
                            <span>{{ item.text }}</span>
                        </template>
                    </div>
                    <div class="huiwen-container huiwen-left" style="margin-top: 20px">
                        <div class="huiwen-left huiwen-text-bold">选择答案：&nbsp;&nbsp;</div>
                        <div class="huiwen-left">
                            <template v-if="daliyQuestion.type == $consts.biz.QUES_TYPES.SINGLE || daliyQuestion.type == $consts.biz.QUES_TYPES.JUDGE">
                                <a-radio-group v-if="!hasSigned" name="daliyAnswer" v-model="daliyAnswer">
                                    <a-radio v-for="item in daliyQuestion.options" v-if="item.text" :value="$consts.biz.ANSWER_DICTIONARY[item.number]">{{ item.number }}</a-radio>
                                </a-radio-group>
                                <a-radio-group v-else name="daliyAnswer" v-model="daliyQuestion.answer" disabled >
                                    <a-radio v-for="item in daliyQuestion.options" v-if="item.text" :value="$consts.biz.ANSWER_DICTIONARY[item.number]">{{ item.number }}</a-radio>
                                </a-radio-group>
                            </template>
                            <template v-if="daliyQuestion.type == $consts.biz.QUES_TYPES.MULTI">
                                <a-checkbox-group v-if="!hasSigned" v-model="daliyAnswer" name="daliyAnswer">
                                    <a-checkbox v-for="item in daliyQuestion.options" v-if="item.text" :value="$consts.biz.ANSWER_DICTIONARY[item.number]">{{ item.number }}</a-checkbox>
                                </a-checkbox-group>
                                <a-checkbox-group v-else v-model="daliyQuestion.answer" name="daliyAnswer" disabled>
                                    <a-checkbox v-for="item in daliyQuestion.options" v-if="item.text" :value="$consts.biz.ANSWER_DICTIONARY[item.number]">{{ item.number }}</a-checkbox>
                                </a-checkbox-group>
                            </template>
                            <template v-if="daliyQuestion.type == $consts.biz.QUES_TYPES.BLANK">

                            </template>
                        </div>

                    </div>
                    <div class="huiwen-container huiwen-left huiwen-margin-top-20">
                        <template v-if="!hasSigned">
                            <a-button block type="primary" @click="signToday">提交打卡</a-button>
                        </template>
                        <template v-else>
                            <div class="huiwen-container huiwen-left">
                                <div class="huiwen-left huiwen-text-bold">正确答案：&nbsp;&nbsp;</div>
                                <div v-if="daliyQuestion.type == $consts.biz.QUES_TYPES.SINGLE || daliyQuestion.type == $consts.biz.QUES_TYPES.JUDGE" class="huiwen-left ">
                                    <span v-if="daliyQuestion.answerok" class="huiwen-text-bold">{{$consts.biz.ANSWER_DICTIONARY[daliyQuestion.answerok]}}</span>
                                    <span v-else style="color: rgba(0, 0, 0, 0.45);">暂无正确答案</span>
                                </div>
                                <div v-if="daliyQuestion.type == $consts.biz.QUES_TYPES.MULTI" class="huiwen-left ">
                                <span v-if="daliyQuestion.answerok && daliyQuestion.answerok.length > 0" v-for="item in daliyQuestion.answerok" class="huiwen-text-bold">
                                    {{$consts.biz.ANSWER_DICTIONARY[item]}}&nbsp;&nbsp;
                                </span>
                                    <span v-else style="color: rgba(0, 0, 0, 0.45);">暂无正确答案</span>
                                </div>
                                <div v-if="daliyQuestion.answerok && daliyQuestion.boRight" class="huiwen-right huiwen-text-green">回答正确</div>
                                <div v-if="daliyQuestion.answerok && !daliyQuestion.boRight" class="huiwen-right huiwen-text-red">回答错误</div>
                            </div>
                            <div class="huiwen-container huiwen-left huiwen-margin-top-20">
                                <div class="huiwen-left huiwen-text-bold">答案解析：&nbsp;&nbsp;</div>
                                <div class="huiwen-left ">
                                    <span v-if="daliyQuestion.answer_comments">{{daliyQuestion.answer_comments}}</span>
                                    <span v-else style="color: rgba(0, 0, 0, 0.45);">暂无答案解析</span>
                                </div>
                            </div>
                        </template>
                    </div>
                </div>

            </div>
        </a-row>
        <a-divider />
        <a-row style="margin-top: 20px">
            <div class="huiwen-container huiwen-left">
                <div class="huiwen-container huiwen-left huiwen-text-bold">TA们也在打卡：&nbsp;&nbsp;</div>
                <div class="huiwen-container huiwen-left">
                    <template v-if="!otherSignedList || otherSignedList.length == 0">
                        <a-empty style="padding: 50px 0"></a-empty>
                    </template>
                    <template v-if="otherSignedList.length > 0">
                        <a-card v-for="(item, index) in otherSignedList" hoverable class="huiwen-container huiwen-left" style="margin-top: 10px">
                            <img class="huiwen-left" style="width: 60px;height: 60px;border-radius: 60px" :src="item.headimgurl"></img>
                            <div class="huiwen-left" style="width: calc(100% - 60px);height: 60px">
                                <div class="huiwen-left huiwen-border-box" style="height: 60px;width: 50%;padding-left: 5px">
                                    <div class="huiwen-left huiwen-text-bold huiwen-container huiwen-text-title" style="height: 30px;line-height: 30px">{{ item.name }}</div>
                                    <div class="huiwen-left huiwen-container huiwen-text-small-title" style="height: 30px;line-height: 30px;color: rgba(0, 0, 0, 0.45)">{{item.create_time}}</div>
                                </div>
                                <div class="huiwen-left huiwen-text-center" style="height: 60px;width: 50%">
                                    <div class="huiwen-left" style="width: 50%;height: 60px">
                                        <a-statistic title="累积打卡" :value="item.signed_total">
                                            <template #suffix>次</template>
                                        </a-statistic>
                                    </div>
                                    <div class="huiwen-left huiwen-text-center" style="width: 50%;height: 60px">
                                        <a-statistic title="正确率" :value="item.signed_ratio">
                                            <template #suffix>%</template>
                                        </a-statistic>
                                    </div>
                                </div>
                            </div>
                        </a-card>
                    </template>
                </div>
            </div>
        </a-row>
    </a-drawer>
</template>

<script>
    import { doQuery, doAction } from "@/api/core"
    export default {
        name: "daliy-sign",
        data() {
            return {
                visible: false,
                /*打卡问题*/
                daliyQuestion: null,
                daliyAnswer: null,
                /*已经打卡*/
                signDays: [],
                signDaysContinue: 0,
                signDaysTotal: 0,
                curdate: null,
                hasSigned: true,
                otherSignedList:[]
            }
        },
        props: {
            taskId: String
        },
        watch: {
            'taskId': function(newVal, oldVal) {
                if(newVal) {
                    this.getDaliyQuestion(() => {
                        this.getSignDays(() => {
                            this.hasSignedCallback(() => {
                                this.visible = true
                            })
                        })
                    })
                }else {
                    this.visible = false
                }
            }
        },
        mounted() {

        },
        methods: {
            signToday() {
                const that = this
                const { daliyQuestion, daliyAnswer } = that
                if (daliyQuestion && !daliyAnswer) {
                    that.$message.error('忘记答题啦！！！')
                    return
                }

                var answer = ''
                if(daliyQuestion.type == that.$consts.biz.QUES_TYPES.SINGLE || daliyQuestion.type == that.$consts.biz.QUES_TYPES.JUDGE) {
                    answer = daliyAnswer
                }
                if(daliyQuestion.type == that.$consts.biz.QUES_TYPES.MULTI) {
                    daliyAnswer.sort()
                    daliyAnswer.forEach((item, index) => {
                        answer = answer.concat(item)
                        if(index < daliyAnswer.length - 1) {
                            answer = answer.concat('|')
                        }
                    })
                }

                doAction({
                    action_code: 'LND0',
                    param_str2: that.taskId,
                    param_str3: daliyQuestion ? daliyQuestion.question_id : '',
                    param_str4: answer
                }).then(res => {
                    if (res.errcode >= that.$consts.biz.OK) {
                        if(res.data.errcode >= that.$consts.biz.OK) {
                            var arr = [...that.signDays]
                            arr.push(that.$cmyUtils.getToday())
                            that.signDays = arr
                            that.hasSigned = true
                            that.hasSignedCallback()
                            that.$message.success(that.$consts.biz.OK_MSG)
                        }else {
                            that.$message.error(that.$consts.biz.FAIL_MSG)
                        }
                    } else {
                        that.$message.error(that.$consts.biz.FAIL_MSG)
                    }
                })
            },
            onClose() {
                const that = this
                that.$emit('onClose')
            },
            onPanelChange(value, mode) {
                console.log(value, mode);
            },
            getDaliyQuestion(func){
                const that = this
                that.daliyQuestion = null
                doQuery({
                    query_code: 'LND00',
                    param_str2: (that.taskId ? that.taskId : ''),
                    page: 1,
                    limit: 1
                }).then(res => {
                    if(res.errcode >= that.$consts.biz.OK && res.data && res.data.length > 0) {
                        that.daliyQuestion = {
                            question_id: res.data[0].question_id,
                            question: res.data[0].question,
                            type: res.data[0].type,
                            options: [
                                {
                                    number: 'A',
                                    text: res.data[0].answer1
                                },
                                {
                                    number: 'B',
                                    text: res.data[0].answer2
                                },
                                {
                                    number: 'C',
                                    text: res.data[0].answer3
                                },
                                {
                                    number: 'D',
                                    text: res.data[0].answer4
                                },
                                {
                                    number: 'E',
                                    text: res.data[0].answer5
                                },
                                {
                                    number: 'F',
                                    text: res.data[0].answer6
                                },
                            ]
                        }
                        if(func) {
                            func()
                        }
                    }else {
                        that.daliyQuestion = null
                    }
                })
            },
            getSignDays(func) {
                const that = this
                doQuery({
                    query_code: 'LND01',
                    param_str2: (that.taskId ? that.taskId : ''),
                    page: 1,
                    limit: 1
                }).then(res => {
                    if(res.errcode >= that.$consts.biz.OK && res.data && res.data.length > 0) {
                        that.signDays = res.data[0].days ? res.data[0].days.split(',') : []
                        that.signDaysContinue = res.data[0].days_continue
                        that.signDaysTotal = res.data[0].days_total
                        that.curdate = res.data[0].curdate.substr(0, 10)
                        if (res.data[0].days && res.data[0].days.indexOf(res.data[0].curdate.substr(0, 10)) >= 0) {
                            that.hasSigned = true
                        }else {
                            that.hasSigned = false
                        }
                        if(func) {
                            func()
                        }
                    }else {
                        that.hasSigned = false
                    }

                })
            },
            hasSignedCallback(refreshDaliyQuestionFunc) {
                const that = this
                if(that.hasSigned) {
                    doQuery({
                        query_code: 'LND20',
                        param_str2: (that.taskId ? that.taskId : ''),
                        param_str3: (that.curdate ? that.curdate : ''),
                        page: 1,
                        limit: 1
                    }).then(res => {
                        if(res.errcode >= that.$consts.biz.OK && res.data && res.data.length > 0) {
                            that.daliyQuestion.answerok = res.data[0].answerok
                            if(that.daliyQuestion.type == that.$consts.biz.QUES_TYPES.MULTI) {
                                that.daliyQuestion.answerok = res.data[0].answerok.split('|')
                            }

                            that.daliyQuestion.answer_comments = res.data[0].answer_comments

                            that.daliyQuestion.answer = res.data[0].answer
                            if(that.daliyQuestion.type == that.$consts.biz.QUES_TYPES.MULTI) {
                                that.daliyQuestion.answer = res.data[0].answer.split('|')
                            }
                            that.daliyQuestion.boRight = res.data[0].answer == res.data[0].answerok
                            if(refreshDaliyQuestionFunc) {
                                refreshDaliyQuestionFunc()
                            }
                        }
                    })
                    doQuery({
                        query_code: 'LND30',
                        param_str2: (that.taskId ? that.taskId : ''),
                        param_str3: (that.curdate ? that.curdate : ''),
                        page: 1,
                        limit: 1
                    }).then(res => {
                        if(res.errcode >= that.$consts.biz.OK && res.data) {
                            that.otherSignedList = res.data
                        }
                    })
                }else {
                    if(refreshDaliyQuestionFunc) {
                        refreshDaliyQuestionFunc()
                    }
                }
            }
        }
    }
</script>

<style scoped>
    .question-title {
        text-indent: 62px;
        margin-top: -22px;
        line-height: 22px;
    }
</style>
